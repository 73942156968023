import React, { useState, useEffect } from 'react';
import { Layout, SEO } from '@/components/index'
import { ScrollingNotice } from '@/new_components/index'
import { FirstScreen, Ar4All, X1Intro, Snap, Lenses, Accessories, WhichOne, FAQ } from '@/new_components/One/index'
import { BiggestUpgrade, DetailImg, Highlights, Enhanced, Certification, Compared, SoundBose, Fov50, Adjustable, IPD, Mode, X1Chip, Ease } from '@/new_components/OnePro/index'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import {
    OnePro_Desktop,
    OnePro_Mobile,
    gina_enhanced,
    Eye_Cover_Desktop,
    Eye_Cover_Mobile,
    X1_Silicon_1,
    audio,
    madetofit,
    madetofit_mobile,
} from '@/images/onepro/index'
import xreal_one_pro from '@/images/icon/xreal_one_pro.svg'
import LocalNav from "@/components/LocalNav"
import { Specs } from '@/new_components/BeamPro'
import {
    spec_onepro,
} from '@/images/one/index'
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import OnePro_Logo from '@/images/SVG/OnePro_Logo.svg'
import { 
    Dog_Desktop
} from '@/images/home/index'

const Index = () => {
    const [probannerType, setProbannerType] = useState('overview')
    const countryCode = getCountry()
    const eu_country=['fr-en','it-en','de-en','nl-en','cz-en','es-en']
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const enhanced_intro = {
        title: intl.formatMessage({ id: "onepro_enhanced_title" }),
        desc: intl.formatMessage({ id: "onepro_enhanced_desc" }),
        tip: intl.formatMessage({ id: "onepro_enhanced_tip" }),
        video: gina_enhanced
    }
    const native3dof_intro = {
        title: intl.formatMessage({ id: "onepro_native_title" }),
        desc: intl.formatMessage({ id: "onepro_native_desc" }),
        tip: intl.formatMessage({ id: "onepro_native_tip" }),
        video: Dog_Desktop
    }
    const x1chip_intro = {
        title: intl.formatMessage({ id: "onepro_x1chip_title" }),
        desc: intl.formatMessage({ id: "onepro_x1chip_desc" }),
        tip: intl.formatMessage({ id: "onepro_x1chip_tip" }),
        video: X1_Silicon_1
    }
    const eye_intro = {
        title: intl.formatMessage({ id: "onepro_eye_title" }),
        desc: intl.formatMessage({ id: "onepro_eye_desc" }),
        tip: intl.formatMessage({ id: "onepro_eye_tip" }),
        pc_img: Eye_Cover_Desktop,
        mobile_img: Eye_Cover_Mobile,
    }
    const audio_intro = {
        title: intl.formatMessage({ id: "onepro_audio_title" }),
        desc: intl.formatMessage({ id: "onepro_audio_desc" }),
        tip: intl.formatMessage({ id: "onepro_audio_tip" }),
        video: audio
    }
    const fit_intro = {
        title: intl.formatMessage({ id: "onepro_fit_title" }),
        desc: intl.formatMessage({ id: "onepro_fit_desc" }),
        tip: intl.formatMessage({ id: "onepro_fit_tip" }),
        pc_img: madetofit,
        mobile_img: madetofit_mobile,
    }
    const localNav_intro = {
        logo: <OnePro_Logo />,
        buy_link: buy_link[countryCode].onepro.shop,
        menublack: true
    }
    const specs_list = [
        {
            title: "onepro_spec_content1_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content1_detail_title"
                }
            ]
        },
        {
            title: "onepro_spec_content2_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content2_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content3_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content3_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content4_title",
            desc: [
                {
                    title: "onepro_spec_content4_detail_title",
                    desc: "onepro_spec_content4_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content5_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content5_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content6_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content6_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content7_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content7_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content8_title",
            desc: [
                {
                    title: "onepro_spec_content8_detail_title",
                    desc: "onepro_spec_content8_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content9_title",
            desc: [
                {
                    title: "onepro_spec_content9_detail_title",
                    desc: "onepro_spec_content9_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content10_title",
            desc: [
                {
                    title: "onepro_spec_content10_detail_title",
                    desc: "onepro_spec_content10_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content11_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content11_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content12_title",
            desc: [
                {
                    title: "",
                    desc: "onepro_spec_content12_detail_title"
                }
            ]
        }

    ]

    useEffect(()=>{
        if(countryCode=='jp'||countryCode=='kr'){
            window.location.replace('/');
        }
    },[])
    return <>
        <Layout menuBlack={false} dontfixed={true}>
            <SEO page="One Pro" />
            <LocalNav intro={localNav_intro} setProbannerType={setProbannerType} probannerType={probannerType} />

            {probannerType == 'overview' && <div>
                <FirstScreen pc_img={OnePro_Desktop} mobile_img={OnePro_Mobile} logo={xreal_one_pro} text1={intl.formatMessage({ id: "one_slogan1" })} text2={intl.formatMessage({ id: "one_slogan2" })} text_color={'#fff'} screenText={true} />
                <ScrollingNotice text1={intl.formatMessage({ id: "onepro_notice1" })} text2={countryCode=='uk'?intl.formatMessage({ id: "onepro_notice_uk" }):(eu_country.includes(countryCode)?intl.formatMessage({ id: "onepro_notice_eu" }):intl.formatMessage({ id: "onepro_notice" }))} btnColor='white' learn_link='/one' buy_link={buy_link[countryCode].onepro.shop}/>
                <BiggestUpgrade />
                {/* <DetailImg /> */}
                <Ar4All gina={true}/>
                <Highlights />
                <Enhanced intro={enhanced_intro} />
                <Fov50 />
                <Adjustable />
                {/* <Certification /> */}
                <IPD />
                <Enhanced intro={native3dof_intro} />
                <Mode />
                <Enhanced intro={x1chip_intro} />
                <X1Chip />
                <X1Intro />
                <Compared />
                <Ease />
                <Enhanced intro={audio_intro} />

                <SoundBose />
                <Enhanced intro={eye_intro} />
                <Snap />
                <Enhanced intro={fit_intro} textStyle={true} />
                <Lenses />
                <Accessories bgColor={'#111'} gina={true} />
                <WhichOne />
            </div>} 
            {probannerType == 'specs' && <Specs list={specs_list} ifBlack={true} product={<OnePro_Logo />} img={spec_onepro} />}
            {probannerType == 'faq' && <FAQ />}

        </Layout>
    </>
}
export default Index